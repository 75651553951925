import React from "react"

import { FaTwitterSquare, FaLinkedin } from "react-icons/fa"
import { LinkedinShareButton, TwitterShareButton } from "react-share"

const ShareButtons = ({ url, title, description, websiteName }) => (
  <div className="post-meta-share-icons">
    <TwitterShareButton url={url} title={title}>
      <FaTwitterSquare className="social-icon" />
    </TwitterShareButton>

    <LinkedinShareButton
      url={url}
      title={title}
      summary={description}
      source={websiteName}
    >
      <FaLinkedin className="social-icon" />
    </LinkedinShareButton>
  </div>
)

export default ShareButtons
