import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import ShareButtons from "../components/Share-buttons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Header from "../components/Header"

const blogDetails = ({ data }) => {
  const { strapiBlogs: body } = data

  const imgPath = getImage(body.image?.localFile)

  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout>
      {/* <Header /> */}
      <Seo
        Sitetitle={body.title}
        description={body.short_description}
        Blogimage={body.image.localFile.publicURL}
      />
      <header className="blog-content-header">
        <div className="d-flex flex-column flex-lg-row">
          <div className="header-col1 order-2 order-lg-1">
            <h1 className="my-3">{body.title}</h1>
            <h6>{moment(body.date).format("DD MMMM, YYYY")} | Article</h6>
          </div>
          <div className="blog-header-img order-1 order-lg-2">
            <GatsbyImage
              image={imgPath}
              alt={body.title}
              className="article-img"
            />
          </div>
        </div>
      </header>
      <Container>
        <article className="my-5 blog-content">
          <div className="d-flex align-items-start align-items-lg-center justify-content-between flex-column flex-lg-row my-5">
            <p>By : {body.author}</p>
            <ShareButtons
              url={url}
              title={body.title}
              description={body.short_description}
              websiteName="Golden Wave"
            />
          </div>
          <ReactMarkdown children={body.content} />
        </article>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  query getSingleBlog($slug: String) {
    strapiBlogs(slug: { eq: $slug }) {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
          publicURL
        }
      }
      title
      content
      author
      date
      short_description
    }
  }
`

export default blogDetails
